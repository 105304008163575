<template>
  <div>
    <b-card
      class="text-center"
    >
      <div class="d-flex justify-content-start align-items-center mb-4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="lg"
          class="big-button"
          variant="primary"
        >
          客户取件
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="ml-5 big-button"
          size="lg"
          variant="success"
        >
          海运入库
        </b-button>
      </div>
      <b-form-group
        class="mb-1"
        label-for="basicInput"
        style="width:26%"
      >
        <b-form-input
          id="basicInput"
          placeholder="搜索运单ID"
        />
      </b-form-group>
      <div class="d-flex justify-content-start align-items-center mb-4 mt-4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="lg"
          class="big-button"
          :variant="isWait ? 'info' :'secondary'"
          @click="isWait = true"
        >
          待取件
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="ml-5 big-button"
          size="lg"
          :variant="!isWait ? 'info' :'secondary'"
          @click="isWait = false"
        >
          已取件
        </b-button>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        responsive
        class="mb-3"
      >
        <template #cell(action)>
          <b-button
            v-if="isWait"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
          >
            发短信提醒
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BFormGroup, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isWait: true,
      items: [{
        qty: 1,
      }],
      fields: [{
        key: 'shipment_id',
        label: '运单ID',
      }, {
        key: 'qty',
        label: '包裹数量',
      }, {
        key: 'receiver',
        label: '收件人名字',
      }, {
        key: 'status',
        label: '当前状态',
      }, {
        key: 'action',
        label: '操作',
      }],
    }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.big-button{
  width: 200px;
  height: 90px;
}
</style>
